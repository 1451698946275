
import { isEmpty, isEqual } from "lodash"

export function validExiste(value: string) {

    return value !== undefined && value !== null && value !== ''
}

export function validIsEqual(value: string, value2: string) {
    
    return isEqual(value, value2)
}

export function validCorrectEmail(value: string = '') {

    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    return value.match(regExp) !== null
}

export function validCorrectMovil(value: string) {

    const regExp = "^[0-9]+$"

    return value.match(regExp) !== null
}

export function validAlMenosUnoMarcado(list: any) {

    return !isEmpty(list)
}

export function validCorrectYear(value: string = '') {

    return value?.length === 4
}

export function validCorrectDNI(value: string) {

    const regExpNIF = "^[0-9]{8}[A-Z]$"

    const regExpNIE = "^[XYZ][0-9]{7}[A-Z]$"

    return value.match(regExpNIF) !== null || value.match(regExpNIE) !== null
}