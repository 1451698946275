
export const SeccionCaracteristicasRedaccion = ({ lang }: any) => {
    
    return (
        <>
            {
                lang === 'ES' && <SeccionCaracteristicasRedaccionES />
            }
            {
                lang === 'CA' && <SeccionCaracteristicasRedaccionCA />
            }
        </>
    )
};

const SeccionCaracteristicasRedaccionES = () => {

    return (
        <ul>
            <li>
                Soporte a la redacción del Libro del Edificio.
            </li>
            <li>
                Genera las instrucciones de mantenimiento del edificio.
            </li>
            <li>
                Adaptado a todas las comunidades autónomas.
            </li>
            <li>
                Incluye Fichas de campo para la toma de datos.
            </li>
            <li>
                Incluye Fichas de consulta de Código Técnico de Edificación.
            </li>

            <li>
                <span className='opacity-25'>
                    Permite la gestión integral del edificio.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Planifica y programa las operaciones de mantenimiento.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Planifica y programa las actuaciones de rehabilitación.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Planifica y programa las mejoras del edificio.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Facilita la gestión financiera.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Permite la interoperatividad de todos los agentes.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Facilita la comunicación entre usuarios.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Es una herramienta dinámica a tiempo real.
                </span>
            </li>

        </ul>
    )
}


const SeccionCaracteristicasRedaccionCA = () => {

    return (
        <ul>
            <li>
                Suport a la redacció del Llibre de l'Edifici.
            </li>
            <li>
                Genereu les instruccions de manteniment de l'edifici.
            </li>
            <li>
                Adaptat a totes les comunitats autònomes.
            </li>
            <li>
                Inclou Fitxes de camp per a la presa de dades.
            </li>
            <li>
                Inclou Fitxes de consulta de codi tècnic d'edificació.
            </li>

            <li>
                <span className='opacity-25'>
                    Permet la gestió integral de l'edifici.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Planifica i programa les operacions de manteniment.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Planifica i programa les actuacions de rehabilitació.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Planifica i programa les millores de l'edifici.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Facilita la gestió financera.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Permet la interoperativitat de tots els agents.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    Facilita la comunicació entre els usuaris.
                </span>
            </li>
            <li>
                <span className='opacity-25'>
                    És una eina dinàmica a temps real.
                </span>
            </li>

        </ul>
    )
}