import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../controllers/controllers"

const PotencialMejoraAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    descripcion: parseString(item.descripcion),
                    duracion: parseString(item.duracion),
                    edifici_id: parseString(item.edifici_id),
                    total_coste_maximo: parseString(item.total_coste_maximo),
                    total_coste_minimo: parseString(item.total_coste_minimo),
                    total_reduccion_consumo_epnr: parseString(item.total_reduccion_consumo_epnr),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    descripcion: parseString(item.descripcion, { removeNullable: true }),
                    total_reduccion_consumo_epnr: parseNumber(item.total_reduccion_consumo_epnr, { removeNullable: true }),
                    total_coste_maximo: parseString(item.total_coste_maximo, { removeNullable: true }),
                    total_coste_minimo: parseString(item.total_coste_minimo, { removeNullable: true }),
                    duracion: parseString(item.duracion, { removeNullable: true }),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    PotencialMejoraAdapters,
}