import { useAxios } from "../../../../../../hooks/useAxios"
import { PotencialMejoraAdapters } from "./potencial-mejora.adapters"

const PotencialMejoraServices = {

    GET: function (idEdificio: string) {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/edificio_mejora_plan/${idEdificio}`,
            type: 'json',
            adapter: {
                method: PotencialMejoraAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    PATCH: function (idEdificio: string) {

        return useAxios({
            method: 'PATCH',
            url: `/potencial_mejora/${idEdificio}/edifici_plan_millora`,
            type: 'json',
            adapter: {
                method: PotencialMejoraAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    PotencialMejoraServices
}