
export const SeccionCaracteristicasGestion = ({ lang }: any) => {

    return (
        <>
            {
                lang === 'ES' && <SeccionCaracteristicasGestionES />
            }
            {
                lang === 'CA' && <SeccionCaracteristicasGestionCA />
            }
        </>
    )
};

const SeccionCaracteristicasGestionES = () => {

    return (
        <ul>
            <li>
                Soporte a la redacción del Libro del Edificio.
            </li>
            <li>
                Genera las instrucciones de mantenimiento del edificio.
            </li>
            <li>
                Adaptado a todas las comunidades autónomas.
            </li>
            <li>
                Incluye Fichas de campo para la toma de datos.
            </li>
            <li>
                Incluye Fichas de consulta de Código Técnico de Edificación.
            </li>
            <li>
                Permite la gestión integral del edificio.
            </li>
            <li>
                Planifica y programa las operaciones de mantenimiento.
            </li>
            <li>
                Planifica y programa las actuaciones de rehabilitación.
            </li>
            <li>
                Planifica y programa las mejoras del edificio.
            </li>
            <li>
                Facilita la gestión financiera.
            </li>
            <li>
                Permite la interoperatividad de todos los agentes.
            </li>
            <li>
                Facilita la comunicación entre usuarios.
            </li>
            <li>
                Es una herramienta dinámica a tiempo real.
            </li>

        </ul>
    )
}


const SeccionCaracteristicasGestionCA = () => {

    return (
        <ul>
            <li>
                Suport a la redacció del Llibre de l'Edifici.
            </li>
            <li>
                Genereu les instruccions de manteniment de l'edifici.
            </li>
            <li>
                Adaptat a totes les comunitats autònomes.
            </li>
            <li>
                Inclou Fitxes de camp per a la presa de dades.
            </li>
            <li>
                Inclou Fitxes de consulta de codi tècnic d'edificació.
            </li>
            <li>
                Permet la gestió integral de l'edifici.
            </li>
            <li>
                Planifica i programa les operacions de manteniment.
            </li>
            <li>
                Planifica i programa les actuacions de rehabilitació.
            </li>
            <li>
                Planifica i programa les millores de l'edifici.
            </li>
            <li>
                Facilita la gestió financera.
            </li>
            <li>
                Permet la interoperativitat de tots els agents.
            </li>
            <li>
                Facilita la comunicació entre els usuaris.
            </li>
            <li>
                És una eina dinàmica a temps real.
            </li>

        </ul>
    )
}