export const CondParSeccion2 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>

      <p>
        <strong>2.1. Características de la herramienta</strong>
      </p>
      <p>

        Para el uso de la aplicación informática del Libro del Edificio Digital (LED), hay que disponer de un dispositivo que tenga acceso a internet y lector de documentos pdf, registrarse y seguir los pasos indicados en la web para la adquisición de los diferentes productos.
      </p>
      <p>

        Cada edificio será dado de alta y generará su Libro correspondiente que podrá ser en modalidad redacción (aplicación denominada redacción) o modalidad gestión (aplicación denominada de gestión).
      </p>
      <p>
        <strong>2.2 Funcionamiento</strong>
      </p>
      <p>
        El producto LED está articulado en dos aplicaciones que pueden contratarse de forma independiente:
      </p>
      <p>
        <strong>Aplicación de redacción del libro del edificio.</strong>
      </p>
      <p>
        Esta aplicación permite generar el Libro del Edificio, a partir de la introducción de los datos y documentos necesarios para su redacción por parte del técnico competente y posterior entrega al interesado.
      </p>
      <p>
        La contratación y acceso de la aplicación LED de redacción, se realizará por técnico competente (usuario principal). El usuario principal es la persona que puede incorporar la información para la redacción del libro.
      </p>
      <p>
        Una vez finalizada la incorporación de la información generará un documento que se descargará en formato pdf, para entregar a la propiedad.
      </p>
      <p>
        Una vez generado el documento, la herramienta ofrece un plazo de 6 meses para modificar los datos que constan en el libro. Transcurrido ese plazo la aplicación quedará cerrada y ya no podrá realizarse ninguna modificación, <strong>salvo que se contrate de forma expresa la aplicación LED gestión</strong> en las condiciones indicadas en la web para este producto.
      </p>
      <p>
        La aplicación LED de redacción es de pago único. En ningún caso se podrá traspasar el uso del producto a terceros.
      </p>
      <p>
        Una vez habilitado el acceso a la aplicación para la introducción de los datos del Libro del Edificio, esta estará a disposición del cliente o usuario principal para su redacción durante un término máximo de 12 meses. Transcurrido este periodo, si el libro no hubiese sido generado el Cateb se reserva el derecho de cancelarlo sin derecho a reembolso.
      </p>
      <p>
        <strong>Aplicación LED gestión del mantenimiento</strong>
      </p>
      <p>
        La aplicación LED gestión permite la redacción y posteriores modificaciones de los datos y del contenido de la gestión de mantenimiento durante todo el periodo en que dure la suscripción al producto.
      </p>
      <p>
        Esta aplicación permite la introducción de los datos y documentos necesarios para confeccionar el Libro así como el alta, planificación, programación y gestión financiera de las actividades de mantenimiento y mejora.
      </p>
      <p>
        El cliente de la aplicación de gestión será la propiedad del edificio o persona legalmente delegada (persona autorizada, presidente de la comunidad o administradores de fincas), y podrá habilitar o dar de baja el acceso a distintos usuarios o perfiles (Gestor de mantenimiento, Administrador de fincas, Junta de Comunidad de Propietarios o Propietarios) para la visualización del contenido.
      </p>
      <p>
        A su vez los perfiles Administrador de fincas y Junta de Comunidad de Propietarios podrán dar de alta y de baja a los perfiles Propietarios en caso de que el edificio se componga de distintas entidades.
      </p>
      <p>
        Para dar de alta a otros usuarios o perfiles, el cliente o usuario principal, tendrá que facilitar los datos de las personas a quienes corresponda el perfil. Estas personas recibirán un correo electrónico, mediante el cual tendrán que aceptar las condiciones de alta en el libro, así como el código de acceso con la que podrán acceder al libro para su consulta. Los usuarios dados de alta no adquieren ningún derecho sobre la herramienta, salvo la visualización del contenido mientras se mantengan de alta. La baja de los usuarios podrá ser tramitada por el usuario que ha tramitado el alta bajo su responsabilidad. También se podrá tramitar la baja a petición expresa del propio usuario.
      </p>
      <p>
        Los distintos perfiles de usuario autorizados a acceder al libro de gestión y sus funciones son:
      </p>
      <p>
        <strong>Gestor de mantenimiento</strong>: técnico competente habilitado por el comprador para introducir la información técnica y datos necesarios para la gestión de mantenimiento del edificio y en su caso para la redacción del Libro del edificio. Tiene la capacidad total para introducir i visualizar datos, excepto aquellas propias asignadas a las viviendas. Este perfil de usuario sólo puede ser un técnico competente para la redacción del Libro del Edificio según lo dispuesto en la Ley 38/1999, de 5 de noviembre, de Ordenación de la Edificación.
      </p>
      <p>
        <strong>Administrador de fincas</strong>: este perfil corresponde al administrador de la finca del edificio objecto del LED. Este perfil podrá acceder a la aplicación y dar de alta a los usuarios con perfil Gestor de mantenimiento, Junta de la Comunidad de propietarios y Propietarios, pero no podrá modificar ningún dato, pudiendo únicamente visualizar los apartados habilitados al efecto. En todo caso podrá incluir documentos en el apartado de “repositorio” que tiene habilitada la herramienta.
      </p>
      <p>
        <strong>Junta de la Comunidad de Propietarios</strong>: Este perfil podrá acceder a la aplicación y es quien puede dar de alta los usuarios con perfil Gestor de mantenimiento, Junta de la Comunidad de propietarios y Propietarios. No puede modificar ningún dato y solo puede visualizar los apartados habilitados al efecto. En todo caso podrá incluir documentos en el apartado de “repositorio” que tiene habilitada la herramienta.
      </p>
      <p>
        <strong>Propietario</strong>: es quien puede introducir y visualizar la información y los documentos asignados a uno o más viviendas dentro de la aplicación de gestión. No puede modificar ningún dato, pudiendo únicamente visualizar los apartados habilitados al efecto.
      </p>
      <p>
        El uso de esta aplicación es de pago por subscripción que se renovará anualmente. Una vez cancelada la suscripción, no se podrá acceder al contenido de los libros ni a su modificación, por tanto, tendrá que descargarse en documento pdf, dentro de los periodos de suscripción.
      </p>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>

      <p>
        <strong>2.1. Característiques de l'eina</strong>
      </p>
      <p>
        Per a l'ús de l'aplicació informàtica del Llibre de l'Edifici Digital (LED), cal disposar d'un dispositiu que tingui accés a internet i lector de documents pdf, registrar-se i seguir els passos indicats al web per a l'adquisició dels diferents productes.
      </p>
      <p>
        Cada edifici serà donat d'alta i generarà el seu Llibre corresponent que podrà ser en modalitat redacció (aplicació denominada redacció) o modalitat gestió (aplicació denominada de gestió).
      </p>
      <p>
        <strong>2.2 Funcionament</strong>
      </p>
      <p>
        El producte LED està articulat en dues aplicacions que es poden contractar de forma independent:
      </p>
      <p>
        <strong>Aplicació de redacció del llibre de l' edifici.</strong>
      </p>
      <p>
        Aquesta aplicació permet generar el Llibre de l' Edifici, a partir de la introducció de les dades i documents necessaris per a la seva redacció per part del tècnic competent i posterior lliurament a l' interessat.
      </p>
      <p>
        La contractació i accés de l'aplicació LED de redacció, es realitzarà per tècnic competent (usuari principal). L'usuari principal és la persona que pot incorporar la informació per a la redacció del llibre.
      </p>
      <p>
        Un cop finalitzada la incorporació de la informació generarà un document que es descarregarà en format pdf, per lliurar a la propietat.
      </p>
      <p>
        Un cop generat el document, l'eina ofereix un termini de 6 mesos per modificar les dades que consten al llibre. Transcorregut aquest termini l' aplicació quedarà tancada i ja no podrà realitzar-se cap modificació, <strong>llevat que es contracti de forma expressa l' aplicació LED gestió</strong> en les condicions indicades a la web per a aquest producte.
      </p>
      <p>
        L' aplicació LED de redacció és de pagament únic. En cap cas es podrà traspassar l' ús del producte a tercers.
      </p>
      <p>
        Un cop habilitat l' accés a l' aplicació per a la introducció de les dades del Llibre de l' Edifici, aquesta estarà a disposició del client o usuari principal per a la seva redacció durant un termini màxim de 12 mesos. Transcorregut aquest període, si el llibre no hagués estat generat el Cateb es reserva el dret de cancel·lar-lo sense dret a reemborsament.
      </p>
      <p>
        <strong>Aplicació LED gestió del manteniment</strong>
      </p>
      <p>
        L' aplicació LED gestió permet la redacció i posteriors modificacions de les dades i del contingut de la gestió de manteniment durant tot el període en què duri la subscripció al producte.
      </p>
      <p>
        Aquesta aplicació permet la introducció de les dades i documents necessaris per confeccionar el Llibre així com l' alta, planificació, programació i gestió financera de les activitats de manteniment i millora.
      </p>
      <p>
        El client de l'aplicació de gestió serà la propietat de l'edifici o persona legalment delegada (persona autoritzada, president de la comunitat o administradors de finques), i podrà habilitar o donar de baixa l'accés a diferents usuaris o perfils (Gestor de manteniment, Administrador de finques, Junta de Comunitat de Propietaris o Propietaris) per a la visualització del contingut.
      </p>
      <p>
        Al seu torn els perfils Administrador de finques i Junta de Comunitat de Propietaris podran donar d'alta i de baixa als perfils Propietaris en cas que l'edifici es compongui de diferents entitats.
      </p>
      <p>
        Per a donar d'alta a altres usuaris o perfils, el client o usuari principal, haurà de facilitar les dades de les persones als qui correspongui el perfil. Aquestes persones rebran un correu electrònic, mitjançant el qual hauran d'acceptar les condicions d'alta en el llibre, així com el codi d'accés amb la qual podran accedir al llibre per a la seva consulta. Els usuaris donats d'alta no adquireixen cap dret sobre l'eina, excepte la visualització del contingut mentre es mantinguin d'alta. La baixa dels usuaris podrà ser tramitada per l'usuari que ha tramitat l'alta sota la seva responsabilitat. També es podrà tramitar la baixa a petició expressa del propi usuari.
      </p>
      <p>
        Els diferents perfils d' usuari autoritzats a accedir al llibre de gestió i les seves funcions són:
      </p>
      <p>
        <strong>Gestor de manteniment</strong>: tècnic competent habilitat pel comprador per introduir la informació tècnica i dades necessàries per a la gestió de manteniment de l' edifici i en el seu cas per a la redacció del Llibre de l' edifici. Té la capacitat total per introduir i visualitzar dades, excepte aquelles pròpies assignades als habitatges. Aquest perfil d'usuari només pot ser un tècnic competent per a la redacció del Llibre de l'Edifici segons el que disposa la Llei 38/1999, de 5 de novembre, d'Ordenació de l'Edificació.
      </p>
      <p>
        <strong>Administrador de finques</strong>: aquest perfil correspon a l' administrador de la finca de l' edifici objecte del LED. Aquest perfil podrà accedir a l' aplicació i donar d' alta als usuaris amb perfil Gestor de manteniment, Junta de la Comunitat de propietaris i Propietaris, però no podrà modificar cap dada, podent únicament visualitzar els apartats habilitats a l' efecte. En tot cas podrà incloure documents en l'apartat de "repositori" que té habilitada l'eina.
      </p>
      <p>
        <strong>Junta de la Comunitat de Propietaris</strong>: Aquest perfil podrà accedir a l' aplicació i és qui pot donar d' alta els usuaris amb perfil Gestor de manteniment, Junta de la Comunitat de propietaris i Propietaris. No pot modificar cap dada i només pot visualitzar els apartats habilitats a l' efecte. En tot cas podrà incloure documents en l'apartat de "repositori" que té habilitada l'eina.
      </p>
      <p>
        <strong>Propietari</strong>: és qui pot introduir i visualitzar la informació i els documents assignats a un o més habitatges dins l' aplicació de gestió. No pot modificar cap dada, podent únicament visualitzar els apartats habilitats a l' efecte.
      </p>
      <p>
        L' ús d' aquesta aplicació és de pagament per subscripció que es renovarà anualment. Un cop cancel·lada la subscripció, no es podrà accedir al contingut dels llibres ni a la seva modificació, per tant, s'haurà de descarregar en document pdf, dins dels períodes de subscripció.
      </p>

    </div>
  )
}