import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { InputText } from "../../../../components/Inputs";
import { useValidator } from "../../../../hooks/useValidator";
import { useForm } from "../../../../hooks/useForm";
import { validExiste } from "../../../../helpers/validations";
import { useLang } from "../../../../language";
import { ButtonAdvanced } from "../../../../components/ButtonAdvanced/ButtonAdvanced";
import { isChecked, isStatusSuccess } from "../../../../helpers/helpers";
import jwt_decode from 'jwt-decode'
import { AuthServices } from "../../../../controllers/auth/auth.services";
import { useDispatch } from "react-redux";
import { logIn } from "../../../../store/slices/auth";
import { useModalData } from "../../../../hooks/useModalData";
import { ModalRecuperarContrasenia } from "../ModalRecuperarContrasenia";
import { ModalStatusServer } from "../../../../components/Modals/ModalStatusServer";
import { ModalActualizarUsuario } from "./ModalActualizarUsuario";
import { InputChecks } from "../../../../components/Inputs/InputChecks";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const AccesoGeneral = () => {

    const Lang = useLang()

    const dispatch = useDispatch()

    const [fetchAuthAccesoGeneralPOST, fetchAuthAccesoGeneralPOSTData] = AuthServices.POST_LOGIN_GENERAL()

    let localNIF = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_NIF') || ''

    const [formIniciarSesion, handleInputRelacion] = useForm({
        nif: localNIF,
        rememmberNIF: !isEmpty(localNIF) ? ['rememmberNIF'] : [],
    })

    const [validLogin, handleValidationLogin] = useValidator({
        nif: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        password: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CLAVE'), isOk: true },
        ],
    })

    const handleSubmitLogin = (e: any) => {

        e.preventDefault()

        if (handleValidationLogin(formIniciarSesion)) {

            localStorage.setItem(
                process.env.REACT_APP_MAIN_VARIABLE + '_NIF' || '',
                isChecked(formIniciarSesion.rememmberNIF) ? (formIniciarSesion.nif || '') : ''
            )

            fetchAuthAccesoGeneralPOST({
                body: formIniciarSesion
            })

        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAuthAccesoGeneralPOSTData.status)) {

            const token = fetchAuthAccesoGeneralPOSTData?.data?.token || ''

            const data: any = jwt_decode(token)

            if (fetchAuthAccesoGeneralPOSTData?.data?.datos_profesionales) {

                dispatch(logIn({
                    user: data,
                    token: token,
                    perfil: data.perfil || ''
                }))

            } else {

                handleShow_ActualizarUsuario({
                    tokenParam: token,
                    nombre: data?.user?.nombre || ''
                })
            }

        }
    }, [fetchAuthAccesoGeneralPOSTData]);

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const [dataModal_ActualizarUsuario, handleShow_ActualizarUsuario, handleClose_ActualizarUsuario] = useModalData()


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show: any) => !show);

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return (
        <>

            <div className="row justify-content-center">
                <div className="col-lg-6">

                    <Typography variant='body1' className='mt-3 mb-2 z-index-10 position-relative fw-normal'>
                        {Lang('P_PARA_USUARIO_REGISTRADO_APLICACION')}
                    </Typography>

                    <Box sx={{ width: '100%' }}>

                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >

                            <InputText
                                label={Lang('NIF')}
                                name='nif'
                                value={formIniciarSesion.nif}
                                onChange={(e: any) => handleInputRelacion({
                                    target: {
                                        name: 'nif',
                                        value: (e.target.value).toUpperCase()
                                    }
                                })}
                                localValidation={validLogin.nif}
                                className='mt-2 mb-3 w-100'
                                fetchData={fetchAuthAccesoGeneralPOSTData}
                            />

                            <div>
                                <InputText
                                    label={Lang('CONTRASENIA')}
                                    type={showPassword ? 'text' : 'password'}
                                    name='password'
                                    value={formIniciarSesion.password}
                                    onChange={handleInputRelacion}
                                    localValidation={validLogin.password}
                                    className='mt-2 mb-3 w-100'
                                    fetchData={fetchAuthAccesoGeneralPOSTData}
                                    endAdornment={

                                        <IconButton
                                            size='small'
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            tabIndex={-1}
                                        >
                                            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                        </IconButton>
                                    }
                                />
                            </div>

                            <Grid item xs={12} display='flex'>
                                <InputChecks
                                    name='rememmberNIF'
                                    values={formIniciarSesion.rememmberNIF}
                                    valuesDefault={[
                                        {
                                            id: 'rememmberNIF',
                                            label: <small>{Lang('RECORDAR_NIF')}</small>
                                        }
                                    ]}
                                    onChange={handleInputRelacion}
                                    size='small'
                                    inlineClassName='justify-content-end flex-row'
                                    tabIndex={-1}
                                />
                            </Grid>

                        </Box>

                        <div className="col-12">
                            <ButtonAdvanced
                                variant="contained" type="submit" className="mt-2 w-100"
                                handleSubmit={handleSubmitLogin} loadings={[fetchAuthAccesoGeneralPOSTData.loading]}
                                value={Lang('INICIAR_SESION')}
                            />
                        </div>

                        <div className="col-12">
                            <ButtonAdvanced
                                variant="outline-primary" type="submit" className="me-2 w-100 mt-2"
                                handleSubmit={handleShowUsuario}
                                value={Lang('OLVIDASTE_TU_CONTRASENIA')}
                            />
                        </div>
                    </Box>


                </div>
            </div>

            <ModalRecuperarContrasenia
                data={showUsuario}
                handleClose={handleCloseUsuario}
            />

            <ModalStatusServer fetchData={fetchAuthAccesoGeneralPOSTData} onlyError />

            <ModalActualizarUsuario
                data={dataModal_ActualizarUsuario}
                handleClose={handleClose_ActualizarUsuario}
            />

        </>
    );
};
