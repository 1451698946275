import { useEffect, useState } from "react";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { Typography } from "@mui/material";
import { ModalCondicionesContratacion } from "./ModalCondicionesContratacion";
import Wizard from "../../../components/Wizard/Wizard";
import { useLang } from "../../../language";
import { ModalRegistroUsuarioStep1 } from "./ModalRegistroUsuarioStep1";
import { ModalRegistroUsuarioStep2 } from "./ModalRegistroUsuarioStep2";
import { useModalData } from "../../../hooks/useModalData";
import { AuthAdapters } from "../../../controllers/auth/auth.adapters";
import { useForm } from "../../../hooks/useForm";
import { ModalStatusServer } from "../../../components/Modals/ModalStatusServer";
import { AuthServices } from "../../../controllers/auth/auth.services";
import { ModalAvisoLegal } from "./ModalAvisoLegal";

export const ModalRegistroUsuario = ({ data, handleClose }: any) => {

    const Lang = useLang()

    // -------- Valores del wizard

    const listWizard = [Lang('ESCRIBA_SU_NIF_NIE'), Lang('DATOS_GENERALES')]

    const [indexFocusWizard, setIndexFocusWizard] = useState(0);

    const [fetchComprobarNIFPOST, fetchComprobarNIFPOSTData, resetfetchComprobarNIFPOSTData] = AuthServices.POST_COMPROBAR_NIF()

    const [fetchAuthPOST, fetchAuthPOSTData, resetfetchAuthPOSTData] = AuthServices.POST_REGISTER()

    const [formRegistro, handleInputRegistro, handleFormRegistro, resetFormRegistro] = useForm({
        ...AuthAdapters.POST_REGISTER.send.schema({}),
        politica_privacitat_acceptada_en: [],
        politica_privacitat_acceptada_en_aviso_legal: [],
        professio: '',
    })

    useEffect(() => {

        if (data.value) {


        } else {
            resetFormRegistro()
            resetfetchComprobarNIFPOSTData()
            resetfetchAuthPOSTData()
            setIndexFocusWizard(0)
        }

    }, [data.value]);

    // const [dataModalCondiciones, handleShowModalCondiciones, handleCloseModalCondiciones] = useModalData()
    const [dataModalAvisoLegal, handleShowModalAvisoLegal, handleCloseModalAvisoLegal] = useModalData()

    const [dataModalAvisoLegal2, handleShowModalAvisoLegal2, handleCloseModalAvisoLegal2] = useModalData()

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {Lang('CREAR_NUEVA_CUENTA')}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <Wizard style={{ marginLeft: '-24px', marginRight: '-24px', marginBottom: '-24px' }}>
                        <Wizard.Header
                            listWizard={listWizard}
                            indexFocusWizard={indexFocusWizard}
                            setIndexFocusWizard={setIndexFocusWizard}
                        />
                        <Wizard.Body indexFocusWizard={indexFocusWizard}>

                            <section>
                                <ModalRegistroUsuarioStep1
                                    formRegistro={formRegistro}
                                    handleInputRegistro={handleInputRegistro}
                                    handleFormRegistro={handleFormRegistro}
                                    setIndexFocusWizard={setIndexFocusWizard}
                                    handleClose={handleClose}
                                    data={data}
                                    fetchComprobarNIFPOST={fetchComprobarNIFPOST}
                                    fetchComprobarNIFPOSTData={fetchComprobarNIFPOSTData}
                                    resetfetchComprobarNIFPOSTData={resetfetchComprobarNIFPOSTData}
                                />
                            </section>
                            <section>
                                <ModalRegistroUsuarioStep2
                                    formRegistro={formRegistro}
                                    handleInputRegistro={handleInputRegistro}
                                    handleFormRegistro={handleFormRegistro}
                                    setIndexFocusWizard={setIndexFocusWizard}
                                    handleClose={handleClose}
                                    data={data}
                                    handleShowModalAvisoLegal={handleShowModalAvisoLegal}
                                    handleShowModalAvisoLegal2={handleShowModalAvisoLegal2}
                                    fetchAuthPOST={fetchAuthPOST}
                                    fetchAuthPOSTData={fetchAuthPOSTData}
                                />
                            </section>
                        </Wizard.Body>
                    </Wizard>

                </ModalAdvanced.Body>

            </ModalAdvanced>

            <ModalAvisoLegal
                data={dataModalAvisoLegal}
                handleClose={handleCloseModalAvisoLegal}
                handleInput={handleInputRegistro}
                name='politica_privacitat_acceptada_en'

            />

            <ModalAvisoLegal
                data={dataModalAvisoLegal2}
                handleClose={handleCloseModalAvisoLegal2}
                handleInput={handleInputRegistro}
                name='politica_privacitat_acceptada_en_aviso_legal'
            />

            <ModalStatusServer
                fetchData={fetchComprobarNIFPOSTData}
                onlySuccess
                customData={
                    {
                        200: {
                            status: Lang('CUENTA_ENCONTRADA'),
                            message: (
                                <>
                                    <p>{Lang('PARRAFO_NIF_ASOCIADO_EXISTENTE')}</p>
                                    <p>{Lang('P_NIF_ASOCIADO_EXISTENTE_2')}</p>
                                    <p className="mb-0">{Lang('P_NIF_ASOCIADO_EXISTENTE_3')}</p>
                                </>
                            )
                        }
                    }
                }
            />

            <ModalStatusServer
                fetchData={fetchAuthPOSTData}
            />

        </>
    );
};
