import React, { useState } from 'react'
import { useEffect } from "react"
import { useLang } from '../../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../../helpers/validations'
import { InputText } from '../../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Button, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { GremioActividadServices } from './controllers/gremios-actividades.services'
import { InputTextarea } from '../../../../../../../../components/Inputs/InputTextarea'
import { useModalData } from '../../../../../../../../hooks/useModalData'
import { ModalListGremios } from '../../../../../../../../components/ModalList/ModalListGremios'
import { ModalGremioProyecto } from '../../../Gremios/ModalGremioProyecto'

export const ModalGremioActividad = ({ data, handleClose, idCurrentProject, idActividad, fetchSuccess,
    formValuesSearch }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchGremioGET, fetchGremioGETData, resetfetchGremioGETData] = GremioActividadServices.GET()

    const [fetchGremioPOST, fetchGremioPOSTData, resetfetchGremioPOSTData] = GremioActividadServices.POST()

    const [fetchGremioPATCH, fetchGremioPATCHData, resetfetchGremioPATCHData] = GremioActividadServices.PATCH()

    const [fetchGremioDELETE, fetchGremioDELETEData, resetfetchGremioDELETEData] = GremioActividadServices.DELETE()

    const [formGremio, handleInputGremio, handleFormGremio, resetFormGremio] = useForm({
        titol: '',
        projecte_gremi_id: '',
        observacio: '',
    })

    const [validGremio, handleValidGremio, resetvalidGremio] = useValidator({
        projecte_gremi_id: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // nif: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // tipus_gremi: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // direccio: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // cp: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // poblacio: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // provincia: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET

    useEffect(() => {

        if (isStatusSuccess(fetchGremioGETData.status)) {

            handleFormGremio({
                ...fetchGremioGETData.data
            })

            setItemGremioSelect({
                id: fetchGremioGETData.data?.projecte_gremi_id,
                name: fetchGremioGETData.data?.nom,
                item: {}
            })
        }

    }, [fetchGremioGETData]);


    // ------------ POST

    const handleSubmitGremioPOST = () => {

        if (handleValidGremio(formGremio)) {

            fetchGremioPOST({
                body: {
                    ...formGremio,
                    projecte_intervencio_id: idActividad,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchGremioPOSTData]);


    // ------------ PATCH

    const handleSubmitEditarAgente = () => {

        if (handleValidGremio(formGremio)) {

            fetchGremioPATCH({
                id: data?.id,
                body: {
                    ...formGremio,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchGremioPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchGremioDELETE({
            id: data?.id,
            body: {
                ...formGremio,
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchGremioDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {
                fetchGremioGET({
                    id: data?.id
                })
            }

        } else {
            resetFormGremio()
            resetvalidGremio()
            resetfetchGremioPOSTData()
            resetfetchGremioGETData()
            resetfetchGremioPATCHData()
            resetfetchGremioDELETEData()
        }

    }, [data.value]);

    // ---------- Importando inmueble

    const [showListInmuebles, handleShowListInmuebles, handleCloseListInmuebles] = useModalData()

    const [itemGremioSelect, setItemGremioSelect] = useState<any>({
        id: '', name: '', item: {}
    });

    useEffect(() => {

        handleFormGremio({
            ...formGremio,
            projecte_gremi_id: itemGremioSelect.id
        })

    }, [itemGremioSelect]);

    useEffect(() => {

        if (!formGremio.projecte_gremi_id) {
            
            setItemGremioSelect({
                id: '',
                name: '',
                item: {}
            })
        }

    }, [formGremio.projecte_gremi_id])


    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const handlefetchSuccessGremioPOST = (data: any) => {
    
        setItemGremioSelect({
            id: data.id,
            name: data.nom,
            item: {}
        })
    }

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='675'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVO_GREMIO') : Lang('EDITAR_GREMIO')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchGremioGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>

                                        <div className="d-flex align-items-end">
                                            <InputText
                                                label={Lang('GREMIO') + ': *'}
                                                name='projecte_gremi_id'
                                                value={itemGremioSelect.name}
                                                onChange={() => setItemGremioSelect({ id: '', name: '', item: {} })}
                                                fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                                localValidation={validGremio.projecte_gremi_id}
                                            />
                                            <Button variant='outlined' color='primary'
                                                size='small' className='ms-2 py-2' onClick={handleShowListInmuebles}>
                                                <FontAwesomeIcon icon={faWarehouse} />
                                            </Button>
                                        </div>

                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('OBSERVACIONES') + ':'}
                                            name='observacio'
                                            value={formGremio.observacio}
                                            onChange={handleInputGremio}
                                            fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                            localValidation={validGremio.observacio}
                                            row={3}
                                        />
                                    </Grid>



                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <div className='d-flex justify-content-between'>
                                <Button variant='outlined' color='secondary'
                                    className='mt-3' onClick={() => handleShowUsuario({
                                        action: 'CREATE',
                                    })}>
                                    {Lang('CREAR_GREMIO')}
                                </Button>
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitGremioPOST}
                                    loadings={[fetchGremioPOSTData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                            :
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <Button variant='outlined' color='secondary'
                                        className='me-3' onClick={() => handleShowUsuario({
                                            action: 'CREATE',
                                        })}>
                                        {Lang('CREAR_GREMIO')}
                                    </Button>
                                    <ButtonAdvanced
                                        variant="outlined" type="button" className=""
                                        handleSubmit={handleSubmitEliminarAgente}
                                        loadings={[fetchGremioDELETEData.loading]}
                                        value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                    />
                                </div>
                                <div>
                                    <ButtonAdvanced
                                        variant="contained" type="button" className=" ms-auto"
                                        handleSubmit={handleSubmitEditarAgente}
                                        loadings={[fetchGremioPATCHData.loading]}
                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                    />
                                </div>
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchGremioPOSTData} />

            <ModalStatusServer fetchData={fetchGremioPATCHData} />

            <ModalStatusServer fetchData={fetchGremioDELETEData} />


            <ModalListGremios
                data={showListInmuebles}
                handleClose={handleCloseListInmuebles}
                setItemSelect={setItemGremioSelect}
                itemSelect={itemGremioSelect}
                params={
                    {
                        projecteid: idCurrentProject,
                    }
                }
            />

            <ModalGremioProyecto
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchSuccessActividad={(response: any) => {
                    handlefetchSuccessGremioPOST(response)
                }}
                idCurrentProject={idCurrentProject}
            />
        </>
    )
}