import React, { useEffect, useState } from "react";
import { ModalListEdificios } from "../../../../../../components/ModalList/ModalListEdificios";
import { isFetchDataInitLoader } from "../../../../../../helpers/helpers";
import { LoaderModalFormPrimary } from "../../../../../../components/Loaders/LoaderModalFormPrimary";

export const ImportarEdificio = ({ dataModal_ImportarEdificio, handleCloseModal_ImportarEdificio,
    fetchImportarEdificioGET, idEdificio }: any) => {

    const [itemEdificioSelect, setItemEdificioSelect] = useState<any>({
        id: '', name: '', item: {}
    });

    useEffect(() => {

        if (itemEdificioSelect.id) {

            fetchImportarEdificioGET({
                id: idEdificio + '/importar-de-edificio/' + itemEdificioSelect.id
            })
        }

    }, [itemEdificioSelect]);

    return (
        <div>

            <ModalListEdificios
                data={dataModal_ImportarEdificio}
                handleClose={handleCloseModal_ImportarEdificio}
                setItemSelect={setItemEdificioSelect}
                itemSelect={itemEdificioSelect}
                params={
                    {
                        solo_propietario: true,
                        idedificio: idEdificio,
                    }
                }
            />
        </div>
    )
};
