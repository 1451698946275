import './Wizard.scss'

interface IHeaderWizard {
    listWizard?: any;
    indexFocusWizard?: number;
    setIndexFocusWizard?: (number: number) => void;
    listDisable?: any,
}

const Wizard = ({ children, style }: any) => {

    return (
        <>
            <section className='Wizard' style={style}>
                {children}
            </section>
        </>
    )
};

const HeaderWizard = ({ listWizard, indexFocusWizard = 0, setIndexFocusWizard, listDisable }: IHeaderWizard) => {

    return (
        <>
            <section className={`HeaderWizard ${listWizard?.length >= 4 ? 'HeaderWizard--shrink' : ''} ${!setIndexFocusWizard ? 'disabledCursor' : ''}`}>
                {
                    listWizard?.map((item: any, index: number) => {
                        return (
                            <div key={`HeaderWizard-${index}`} className={`HeaderWizard-item ${indexFocusWizard === index ? 'HeaderWizard-item_focus' : ''} ${indexFocusWizard > index ? 'HeaderWizard-item_complete' : ''} ${listDisable?.[index] ? 'disabledCursor' : ''}`}>
                                <div className={`HeaderWizard-item-info ${indexFocusWizard < index ? 'disabledCursor' : ''}`} onClick={() => setIndexFocusWizard ? setIndexFocusWizard(index) : null} >
                                    <div className='HeaderWizard-item-number'>{index + 1}</div>
                                    <div className='HeaderWizard-item-name'>{item}</div>
                                </div>
                                <div className="HeaderWizard-item-line"></div>
                            </div>
                        )
                    })
                }
            </section>
        </>
    )
};

interface IBodyWizard {
    children?: any;
    indexFocusWizard?: number;
    setIndexFocusWizard?: (number: number) => null;
}

const BodyWizard = ({ children, indexFocusWizard = 0, setIndexFocusWizard }: IBodyWizard) => {

    return (
        <>
            <section className='BodyWizard'>
                {
                    children?.map((item: any, index: number) => {
                        if (index !== indexFocusWizard) {
                            return null
                        }
                        return (
                            <div key={`BodyWizard-${index}`}>
                                {item}
                            </div>
                        )
                    })
                }
            </section>
        </>
    )
};

Wizard.Header = HeaderWizard
Wizard.Body = BodyWizard

export default Wizard;