import { useState, useEffect } from 'react'
import { useNavigate } from "react-router";
import { DropdownAdvanced } from "../../DropdownAdvanced/DropdownAdvanced";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ComunicacionesServices } from '../../../controllers/comunicaciones/comunicaciones.services';
import { isStatusSuccess } from '../../../helpers/helpers';
import { DropdownAdvancedLoader } from '../../DropdownAdvanced/DropdownAdvancedLoader';
import { useLang } from '../../../language';
import { TabsAdvanced } from '../../TabsAdvanced/TabsAdvanced';
import { Divider, Grid, MenuItem } from '@mui/material';
import './DropdownNotifications.scss'
import { receiveFecha, receiveFechaFormat2 } from '../../../controllers/controllers';
import { isEmpty } from 'lodash';

export const DropdownNotifications = () => {

    const Lang = useLang()

    const [fetchMisComunicacionesGET, fetchMisComunicacionesGETData] = ComunicacionesServices.GET_MIS_COMUNICACIONES_SIMPLE()

    const [objectList, setobjectList] = useState<any>({
        title: <FontAwesomeIcon icon={faBell} className='me-2' />,
        list: []
    });

    const handleClickOpenDropdown = () => {

        setonClose(false)

        setobjectList({
            ...objectList,
            list: []
        })

        fetchMisComunicacionesGET({
            params: {
                limit: '5',
                full: true
            }
        })
    }

    // ------------ Notificaciones en no leidos, comunicaciones

    const [fetchNumeroNoLeidosGET, fetchNumeroNoLeidosGETData] = ComunicacionesServices.GET_MIS_NO_LEIDOS()

    const milisegundos = 1 * 60 * 1000;

    useEffect(() => {

        fetchNumeroNoLeidosGET()

        const interval = setInterval(() => {

            fetchNumeroNoLeidosGET()

        }, milisegundos);

        return () => clearInterval(interval);

    }, []);

    useEffect(() => {

        if (isStatusSuccess(fetchNumeroNoLeidosGETData.status)) {

            const { num_comunicaciones_no_leidas, num_docs_edificios_caducar, num_docs_inmuebles_caducar, num_actividades_proximas } = fetchNumeroNoLeidosGETData?.data

            const total = Number(num_comunicaciones_no_leidas) +
                Number(num_docs_edificios_caducar) +
                Number(num_docs_inmuebles_caducar) +
                Number(num_actividades_proximas)

            setobjectList(
                {
                    ...objectList,
                    title: <>
                        {
                            total === 0 ?
                                <FontAwesomeIcon icon={faBell} className='me-2' />
                                :
                                <span className='position-relative'>
                                    <FontAwesomeIcon icon={faBell} className='me-2' />
                                    <span className='DropdownNotifications__Information d-flex align-items-center justify-content-center'>
                                        {total}
                                    </span>
                                </span>
                        }
                    </>,
                }
            )
        }

    }, [fetchNumeroNoLeidosGETData]);

    const [onClose, setonClose] = useState(false);


    return (
        <div className='DropdownNotifications'>
            <DropdownAdvanced
                objectList={objectList}
                onClickOpen={handleClickOpenDropdown}
                onClose={onClose}
            >
                <TabsAdvanced classNameTabsAdvanced='max-w-500px'>
                    <TabsAdvanced.Head
                        // centered={true}
                        values={[
                            {
                                id: 'comunicaciones',
                                name: <small>{Lang('COMUNICACIONES')} ({fetchMisComunicacionesGETData.data?.comunicaciones_no_leidas?.cantidad})</small>,
                            },
                            {
                                id: 'docs-edificio',
                                name: <small>{Lang('EDIFICIOS')} ({fetchMisComunicacionesGETData.data?.documentos_edificio_caducados?.cantidad})</small>,
                            },
                            {
                                id: 'docs-inmuebles',
                                name: <small>{Lang('VIVIENDAS')} ({fetchMisComunicacionesGETData.data?.documentos_inmueble_caducados?.cantidad})</small>,
                            },
                            {
                                id: 'actividades_proximas',
                                name: <small>{Lang('ACTIVIDADES')} ({fetchMisComunicacionesGETData.data?.actividades_proximas?.cantidad})</small>,
                            },
                        ]}
                    />
                    <TabsAdvanced.Body>
                        <section className="pt-3">

                            <Grid container spacing={3} rowSpacing={3}>

                                <Grid item xs={12}>

                                    {
                                        fetchMisComunicacionesGETData?.loading ?
                                            <DropdownAdvancedLoader />
                                            :
                                            <>
                                                {
                                                    (isEmpty(fetchMisComunicacionesGETData.data?.comunicaciones_no_leidas) ||
                                                        fetchMisComunicacionesGETData.data?.comunicaciones_no_leidas?.cantidad === 0) &&
                                                        <div className='d-flex justify-content-center align-items-center px-2 py-4'>
                                                            <small>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</small>
                                                        </div>

                                                }
                                                <RowsNotification
                                                                list={fetchMisComunicacionesGETData.data?.comunicaciones_no_leidas?.comunicaciones || []}
                                                                type='notificaciones'
                                                                nameLabel='assumpte'
                                                                setonClose={setonClose}
                                                            />
                                            </>
                                    }
                                </Grid>
                            </Grid>
                        </section>
                        <section className="pt-3">

                            <Grid container spacing={3} rowSpacing={3}>
                                <Grid item xs={12}>
                                    {
                                        fetchMisComunicacionesGETData?.loading ?
                                            <DropdownAdvancedLoader />
                                            :
                                            <>
                                                <small className='d-flex justify-content-center align-items-center mb-2 opacity-50'>
                                                    {`${Lang('ALERTA_NOTIFICACIONES_1')} 90 ${Lang('ALERTA_NOTIFICACIONES_2')}`}
                                                </small>
                                                {
                                                    (isEmpty(fetchMisComunicacionesGETData.data?.documentos_edificio_caducados) ||
                                                        fetchMisComunicacionesGETData.data?.documentos_edificio_caducados?.cantidad === 0) &&
                                                    <div className='d-flex justify-content-center align-items-center px-2 py-4'>
                                                        <small>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</small>
                                                    </div>
                                                }
                                                <RowsNotification
                                                    list={fetchMisComunicacionesGETData.data?.documentos_edificio_caducados?.documentos || {}}
                                                    type='notificaciones-docs-edificios'
                                                    nameLabel='tipus'
                                                    setonClose={setonClose}
                                                />
                                            </>
                                    }
                                </Grid>
                            </Grid>

                        </section>
                        <section className="pt-3">

                            <Grid container spacing={3} rowSpacing={3}>
                                <Grid item xs={12}>

                                    {
                                        fetchMisComunicacionesGETData?.loading ?
                                            <DropdownAdvancedLoader />
                                            :
                                            <>
                                                <small className='d-flex justify-content-center align-items-center mb-2 opacity-50'>
                                                    {`${Lang('ALERTA_NOTIFICACIONES_1')} 90 ${Lang('ALERTA_NOTIFICACIONES_2')}`}
                                                </small>
                                                {
                                                    (isEmpty(fetchMisComunicacionesGETData.data?.documentos_inmueble_caducados) ||
                                                        fetchMisComunicacionesGETData.data?.documentos_inmueble_caducados?.cantidad === 0) &&
                                                    <div className='d-flex justify-content-center align-items-center px-2 py-4'>
                                                        <small>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</small>
                                                    </div>
                                                }
                                                <RowsNotification
                                                    list={fetchMisComunicacionesGETData.data?.documentos_inmueble_caducados?.documentos || {}}
                                                    type='notificaciones-docs-inmuebles'
                                                    nameLabel='texto'
                                                    setonClose={setonClose}

                                                />
                                            </>
                                    }

                                </Grid>
                            </Grid>

                        </section>
                        <section className="pt-3">

                            <Grid container spacing={3} rowSpacing={3}>
                                <Grid item xs={12}>

                                    {
                                        fetchMisComunicacionesGETData?.loading ?
                                            <DropdownAdvancedLoader />
                                            :
                                            <>
                                                <small className='d-flex justify-content-center align-items-center mb-2 opacity-50'>
                                                    {`${Lang('ALERTA_NOTIFICACIONES_1')} 90 ${Lang('ALERTA_NOTIFICACIONES_2')}`}
                                                </small>
                                                {
                                                    (isEmpty(fetchMisComunicacionesGETData.data?.actividades_proximas) ||
                                                        fetchMisComunicacionesGETData.data?.actividades_proximas?.cantidad === 0) &&
                                                    <div className='d-flex justify-content-center align-items-center px-2 py-4'>
                                                        <small>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</small>
                                                    </div>
                                                }
                                                <RowsNotification
                                                    list={fetchMisComunicacionesGETData.data?.actividades_proximas?.actividades || {}}
                                                    type='actividades-proximas'
                                                    nameLabel='titol'
                                                    setonClose={setonClose}
                                                />
                                            </>

                                    }

                                </Grid>
                            </Grid>

                        </section>
                    </TabsAdvanced.Body>

                </TabsAdvanced>

            </DropdownAdvanced>
        </div>
    );
};

const RowsNotification = ({ list, type, nameLabel, setonClose }: any) => {

    const navigate = useNavigate()

    const Lang = useLang()

    let res: any = []

    let perPage = 5

    if (type === 'notificaciones') {

        for (let i = 0; i < 5; i++) {

            if (!list[i] || i >= perPage) {

                break
            }

            res.push(
                <MenuItem className='text-wrap' onClick={() => { navigate(`/${type}/${list[i].id}`); setonClose(true); }} key={i}>
                    {list[i][nameLabel]}
                </MenuItem>
            )
        }

    } else if (type === 'actividades-proximas') {

        let countTemp = 0

        for (const key in list) {

            for (let i = 0; i < list[key].length; i++) {

                if (!list[key][i] || countTemp >= perPage) {

                    break
                }

                res.push(
                    <MenuItem className='pe-none text-wrap' key={i}>
                        {list[key][i][nameLabel]} - {receiveFechaFormat2(receiveFecha(list[key][i]?.['data_inici_calculada'] || ''))}
                    </MenuItem>
                )

                countTemp++
            }
        }


    } else {

        let countTemp = 0

        for (const key in list) {

            for (let i = 0; i < list[key].length; i++) {

                if (!list[key][i] || countTemp >= perPage) {

                    break
                }

                res.push(
                    <MenuItem className='pe-none text-wrap' key={i}>
                        {list[key][i]['nom']} - {list[key][i][nameLabel]} - {receiveFechaFormat2(receiveFecha(list[key][i]?.['data_validesa'] || ''))}
                    </MenuItem>
                )

                countTemp++
            }
        }
    }

    res.push(
        <Divider />
    )

    res.push(
        <MenuItem onClick={() => { navigate(`/${type}`); setonClose(true); }} className='justify-content-center'>
            <small>{Lang('VER_MAS')}</small>
        </MenuItem>
    )

    return res
}
