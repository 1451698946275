import { useLang } from '../../language';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { DropdownLanguage } from '../../components/Layouts/DropdownLanguage';

import './Index.scss'
import { Footer } from '../../components/Layouts/Footer/Footer';
import { useLocation, useNavigate } from 'react-router';

import logo from '../../assets/images/logo.png'

import { useEffect, useState } from 'react'
import { useModalData } from '../../hooks/useModalData';
import { ModalTarifa1 } from './ModalTarifa1';
import { ModalTarifa2 } from './ModalTarifa2';
import { ModalInfo } from '../../components/Modals/ModalInfo';
import queryString from 'query-string';
import { ListConvenios } from './ListConvenios/ListConvenios';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ModalContacto } from '../Contacto/ModalContacto';
import { ModalRegistroUsuario } from '../Login/ModalRegistroUsuario/ModalRegistroUsuario';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { TagTopProducto } from '../../components/TagTopProducto/TagTopProducto';
import { SeccionHeader } from './SeccionHeader/SeccionHeader';
import { SeccionQueEs } from './SeccionQueEs/SeccionQueEs';
import { SeccionCaracteristicasRedaccion } from './SeccionCaracteristicas/SeccionCaracteristicasRedaccion';
import { SeccionCaracteristicasGestion } from './SeccionCaracteristicas/SeccionCaracteristicasGestion';
import { Faqs } from '../Faqs';
import { AlertasGenerales } from './AlertasGenerales';
import { isBetweenDates } from '../../helpers/helpers';

const Index = () => {

  const Lang = useLang()

  const navigate = useNavigate()

  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const [dataModal_Tarifa1, handleOpenModal_Tarifa1, handleCloseModal_Tarifa1] = useModalData()

  const [dataModal_Tarifa2, handleOpenModal_Tarifa2, handleCloseModal_Tarifa2] = useModalData()

  const [dataModal_InfoError, handleShowModal_InfoError, handleCloseModal_InfoError] = useModalData()

  const [dataModal_Contacto, handleShowModal_Contacto, handleCloseModal_Contacto] = useModalData()

  const location = useLocation()

  const params: any = queryString.parse(location.search);

  const msgs: any = {
    'sesion_caducada': Lang('SESION_CADUCADA'),
  }

  const [messageModal, setmessageModal] = useState('');


  useEffect(() => {

    if (params?.msg && msgs[params?.msg]) {

      setmessageModal(msgs[params?.msg])

      handleShowModal_InfoError()
    }

  }, []);

  const { language } = useSelector((state: RootState) => state.language)

  const [dataModal_InfoTipoEdif, handleShowModal_InfoTipoEdif, handleCloseModal_InfoTipoEdif] = useModalData()

  const [verMas, setverMas] = useState(false);

  const [verTarifas, setverTarifas] = useState(false);

  const [dataModal_Registro, handleShowModal_Registro, handleCloseModal_Registro] = useModalData()

  const [dataModal_Faqs, handleShowModal_Faqs, handleCloseModal_Faqs] = useModalData()

  return (
    <section className='PageIndex'>

      <nav className="PageIndex__NavTop d-flex align-items-center">

        <div className='px-3 d-flex justify-content-between w-100 align-items-center'>

          {/* <Typography variant='h5' className='mb-0 fw-bolder px-2 cursor-pointer' component="span" onClick={() => navigate('/')}>
            LED
          </Typography> */}
          <img src={logo} alt="logo" className='cursor-pointer d-block d-lg-none' onClick={() => navigate('/')} />
          <img src={`${process.env.PUBLIC_URL}/images/Logo_LED_${language}.png`} alt="logo" className='cursor-pointer d-none d-lg-block' onClick={() => navigate('/')} />

          <div className='d-flex align-items-center'>

            {/* <a href="/" className='me-3' onClick={e => {
              let hero = document.getElementById("seccion-nuestros-productos");
              e.preventDefault();
              hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
            }}>
              {Lang('TARIFAS')}
            </a> */}
            <a href="/" className='me-4 d-none d-lg-block' onClick={(e) => {
              e.preventDefault();
              handleShowModal_Faqs()
            }}>
              <FontAwesomeIcon icon={faQuestionCircle} className='me-2' />
              <span className='text-capitalize'>FAQs</span>
            </a>

            <a href="/" className='me-2' onClick={(e) => {
              e.preventDefault();
              handleShowModal_Contacto()
            }}>{Lang('CONTACTAR')}</a>

            <ModalContacto
              data={dataModal_Contacto}
              handleClose={handleCloseModal_Contacto}
            />

            <DropdownLanguage />

          </div>

        </div>

      </nav>

      <header className='PageIndex__CTA d-flex flex-column'>

        <Container maxWidth="lg" className='my-auto'>

          <Grid container className='mb-5'>

            <Grid item xs={12} md={8} lg={8} className='PageIndex__CTA--text py-5 py-lg-0'>

              <div className='me-lg-5'>

                <SeccionHeader lang={language} />

              </div>

            </Grid>

            <Grid item xs={12} md={4} lg={4} className='PageIndex__CTA--text py-5 py-lg-0 border-iniciar-sesion'>

              <Grid container justifyContent='center'>
                <Grid item xs={12} md={8} lg={8} className='d-flex flex-column'>

                  <Typography variant='h5' className='my-4 fw-bold text-center' color='white'>
                    {Lang('INICIA_SESION')}
                  </Typography>

                  <Button variant='outlined' className='btn-outline-white w-100 mb-4' onClick={() => navigate('/login')}>
                    {Lang('ACCESO_USUARIOS')}
                  </Button>

                  <Button variant='outlined' className='btn-outline-white w-100 text-nowrap' onClick={handleShowModal_Registro}>
                    {Lang('REGISTRARME')}
                  </Button>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Container>

      </header>

      <section className='PageIndex__Beneficios' >

        <Container maxWidth="lg" className='my-auto PageIndex__Beneficios--container'>

          <SeccionQueEs lang={language} />

        </Container>

      </section>

      <section className='PageIndex__Productos' id='seccion-nuestros-productos'>

        <Typography variant='h5' className='mt-5 mb-5 fw-normal text-center'>
          <span className='ps-3 fw-bold'>{Lang('NUESTROS_PRODUCTOS')}</span>
        </Typography>

        <Container maxWidth="lg" className='my-auto'>

          <Grid container justifyContent='center' spacing={7} rowSpacing={4}>

            <Grid item md={5} className=''>

              <Card className='overflow-visible'>
                <CardContent>
                  <TagTopProducto title={Lang('PROFESIONALES')} />
                  <Typography variant='h5' className='fw-normal PageIndex__ProductosCard--title fw-bold pt-3 pb-4 color-primary'>
                    {Lang('LED_REDACCION')}
                  </Typography>

                  <Typography className="PageIndex__ProductosCard--descripcion pt-0">
                    {Lang('P_HERRAMIENTA_DIGITAL_REDACCION_CONSTRUCCION')}
                  </Typography>

                </CardContent>
              </Card>

            </Grid>

            <Grid item md={5} className='d-flex'>

              <Card className='overflow-visible'>
                <CardContent>
                  <TagTopProducto title={Lang('PROPIETARIOS')} />

                  <Typography variant='h5' className='fw-normal PageIndex__ProductosCard--title fw-bold pt-3 pb-4 color-primary'>
                    {Lang('LED_GESTION')}
                  </Typography>

                  <Typography className="PageIndex__ProductosCard--descripcion pt-0">
                    {Lang('P_HERRAMIENTA_DIGITAL_GESTION_RESIDENCIALES')}
                  </Typography>

                </CardContent>
              </Card>

            </Grid>

            {
              !verMas &&
              <Grid item md={12} className='text-center'>

                <Button variant='contained' className={`mx-auto ${verMas ? '' : 'mb-2'}`} onClick={() => setverMas(!verMas)}>
                  {Lang('SABER_MAS')}
                </Button>

              </Grid>
            }

            {
              verMas &&
              <>

                <Grid item md={5} className='animate__animated animate__fadeInUpSmall'>

                  <Card>
                    <CardContent>

                      <Typography className="PageIndex__ProductosCard--descripcion pt-0 px-0 text-start fs-1-1rem">
                        {Lang('LED_REDACCION')}, <br /> {Lang('P_HERRAMIENTA_DIGITAL_SOPORTE_A')} <strong>{Lang('P_REDACCION_LIBRO_EDIFICIO')}</strong>.
                      </Typography>

                      <Typography className="pt-0 fw-500  mb-3">
                        {Lang('P_TIPOS_EDIFICIOS_FORMALIZAR')}
                      </Typography>


                      <div className="d-flex justify-content-between my-3">

                        <Typography className="pt-0">
                          1. {Lang('P_EDIFICIOS_VIVIENDAS_CONSTRUCCION')}
                        </Typography>

                        <Button variant='outlined' className=' mb-0 px-0 border-0' style={{ minWidth: '40px' }} onClick={() => handleShowModal_InfoTipoEdif({
                          message: <>
                            <Grid container alignItems='center' justifyContent='center'>

                              <Grid item md={4} className=''>

                                <div className="d-flex justify-content-center align-items-end">

                                  <div className='d-flex justify-content-center align-items-center flex-column mx-2'>
                                    <img src={`${process.env.PUBLIC_URL}/images/edifici_plurifamiliar.png`} alt="" width='100px' />
                                    {/* <small>Edifici plurifamiliar</small> */}
                                  </div>

                                  <div className='d-flex justify-content-center align-items-center flex-column mx-2 me-4'>
                                    <img src={`${process.env.PUBLIC_URL}/images/edifici_unifamiliar.png`} alt="" width='100px' />
                                    {/* <small>Edifici unifamiliar</small> */}
                                  </div>

                                </div>

                              </Grid>

                              <Grid item md={8} className=''>
                                <Typography variant='h6' className='mb-4 fw-normal d-flex align-items-center'>
                                  <div className='PageIndex__Pasos--dot flex-grow-0 flex-shrink-0'>
                                    1
                                  </div>
                                  <span className='ps-3 fw-bold text-start'>{Lang('PARRAFO_PASO_1_TITULO')}</span>
                                </Typography>
                                <Typography variant='h6' className='mb-0 fw-normal fs-105'>
                                  <span className='d-block text-start'>{Lang('PARRAFO_PASO_1_PARRAFO')}</span>
                                </Typography>

                              </Grid>

                            </Grid>

                          </>
                        })}>
                          <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '1.2rem' }} />
                        </Button>
                      </div>

                      <div className="d-flex justify-content-between my-3">

                        <Typography className="pt-0">
                          2. {Lang('P_EDIFICIOS_SECTOR_TERCIARIO')}
                        </Typography>

                        <Button variant='outlined' className=' mb-0 px-0 border-0' style={{ minWidth: '40px' }} onClick={() => handleShowModal_InfoTipoEdif({
                          message: <>
                            <Grid container alignItems='center' justifyContent='center'>

                              <Grid item md={4} className=''>

                                <div className="d-flex justify-content-center align-items-end">

                                  <div className='d-flex justify-content-center align-items-center flex-column mx-2'>
                                    <img src={`${process.env.PUBLIC_URL}/images/edifici_sector_terciari.png`} alt="" width='100px' />
                                    {/* <small>Edifici plurifamiliar</small> */}
                                  </div>

                                </div>

                              </Grid>

                              <Grid item md={8} className=''>
                                <Typography variant='h6' className='mb-4 fw-normal d-flex align-items-center'>
                                  <div className='PageIndex__Pasos--dot flex-grow-0 flex-shrink-0'>
                                    2
                                  </div>
                                  <span className='ps-3 fw-bold text-start'>{Lang('PARRAFO_PASO_2_TITULO')}</span>
                                </Typography>
                                <Typography variant='h6' className='mb-0 fw-normal fs-105'>
                                  <span className='d-block text-start'>{Lang('PARRAFO_PASO_2_PARRAFO')}</span>
                                </Typography>

                              </Grid>

                            </Grid>
                          </>
                        })}>
                          <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '1.2rem' }} />
                        </Button>
                      </div>

                      <div className="d-flex justify-content-between my-3">

                        <Typography className="pt-0">
                          3. {Lang('P_EDIFICIOS_VIVIENDAS_EXISTENTES')}
                        </Typography>
                        <Button variant='outlined' className=' mb-0 px-0 border-0' style={{ minWidth: '40px' }} onClick={() => handleShowModal_InfoTipoEdif({
                          message: <>
                            <Grid container alignItems='center' justifyContent='center'>

                              <Grid item md={4} className=''>

                                <div className="d-flex justify-content-center align-items-end">

                                  <div className='d-flex justify-content-center align-items-center flex-column mx-2'>
                                    <img src={`${process.env.PUBLIC_URL}/images/edificis_existents.png`} alt="" width='150px' />
                                    {/* <small>Edifici plurifamiliar</small> */}
                                  </div>

                                </div>

                              </Grid>

                              <Grid item md={8} className=''>
                                <Typography variant='h6' className='mb-4 fw-normal d-flex align-items-center'>
                                  <div className='PageIndex__Pasos--dot flex-grow-0 flex-shrink-0'>
                                    3
                                  </div>
                                  <span className='ps-3 fw-bold text-start'>{Lang('PARRAFO_PASO_3_TITULO')}</span>
                                </Typography>
                                <Typography variant='h6' className='mb-0 fw-normal fs-105'>
                                  <span className='d-block text-start'>{Lang('PARRAFO_PASO_3_PARRAFO')}</span>
                                </Typography>

                              </Grid>

                            </Grid>
                          </>
                        })}>
                          <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '1.2rem' }} />
                        </Button>
                      </div>

                    </CardContent>
                  </Card>

                </Grid>

                <Grid item md={5} className='animate__animated animate__fadeInUpSmall d-flex'>

                  <Card>
                    <CardContent>

                      {/* <Typography variant='h5' className='fw-normal PageIndex__ProductosCard--title fw-bold py-4 color-primary'>
                        {Lang('LED_GESTION')}
                      </Typography> */}

                      <Typography className="PageIndex__ProductosCard--descripcion pt-0 px-0 text-start fs-1-1rem">
                        {Lang('LED_GESTION')}, <br />{Lang('P_HERRAMIENTA_DIGITAL_DE')} <strong>{Lang('P_GESTION_INTEGRAL_EDIFICIOS')}</strong>.
                      </Typography>

                      <Typography className="pt-0 fw-500   mb-3">
                        {Lang('P_TIPOS_EDIFICIOS_GESTIONAR')}
                      </Typography>

                      <div className="d-flex justify-content-between my-3">

                        <Typography className="pt-0">
                          1. {Lang('P_EDIFICIOS_VIVIENDAS_EXISTENTES')}
                        </Typography>
                        <Button variant='outlined' className=' mb-0 px-0 border-0' style={{ minWidth: '40px' }} onClick={() => handleShowModal_InfoTipoEdif({
                          message: <>
                            <Grid container alignItems='center' justifyContent='center'>

                              <Grid item md={4} className=''>

                                <div className="d-flex justify-content-center align-items-end">

                                  <div className='d-flex justify-content-center align-items-center flex-column mx-2'>
                                    <img src={`${process.env.PUBLIC_URL}/images/edificis_existents.png`} alt="" width='150px' />
                                  </div>

                                </div>

                              </Grid>

                              <Grid item md={8} className=''>
                                <Typography variant='h6' className='mb-4 fw-normal d-flex align-items-center'>
                                  <div className='PageIndex__Pasos--dot flex-grow-0 flex-shrink-0'>
                                    1
                                  </div>
                                  <span className='ps-3 fw-bold text-start'>{Lang('PARRAFO_PASO_3_TITULO')}</span>
                                </Typography>
                                <Typography variant='h6' className='mb-0 fw-normal fs-105'>
                                  <span className='d-block text-start'>{Lang('PARRAFO_PASO_3_PARRAFO')}</span>
                                </Typography>

                              </Grid>

                            </Grid>
                          </>
                        })}>
                          <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '1.2rem' }} />
                        </Button>
                      </div>

                    </CardContent>
                  </Card>

                </Grid>

                <Grid item md={5}>

                  <Typography className="pt-0 fw-500 text-center mb-4" variant='h6'>
                    {Lang('P_QUE_TE_PERMITE_HACER')} <br /> {Lang('LED_REDACCION')}?
                  </Typography>

                  <div className="PageIndex__ProductosCard animate__animated animate__fadeInUpSmall h-auto">

                    <div className="PageIndex__ProductosCard--body py-0">

                      <SeccionCaracteristicasRedaccion lang={language} />

                    </div>
                  </div>
                </Grid>

                <Grid item md={5}>

                  <Typography className="pt-0 fw-500 text-center mb-4" variant='h6'>
                    {Lang('P_QUE_TE_PERMITE_HACER')} <br /> {Lang('LED_GESTION')}?
                  </Typography>

                  <div className="PageIndex__ProductosCard  animate__animated animate__fadeInUpSmall h-auto">

                    <div className="PageIndex__ProductosCard--body p-0">

                      <SeccionCaracteristicasGestion lang={language} />

                    </div>
                  </div>

                </Grid>

                <Grid item md={12} className='text-center'>

                  <Typography variant='h5' className='mt-2 mb-2 fw-normal text-center fs-3'>
                    <span className='fw-bold'>{Lang('TARIFAS')}</span>
                  </Typography>
                </Grid>

                <Grid item md={5} className='animate__animated animate__fadeInUpSmall '>
                  <Card className='overflow-visible h-100 d-flex'>
                    <CardContent className='d-flex flex-column flex-grow-1'>
                      <ModalTarifa1 handleShowModal_Registro={handleShowModal_Registro} />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item md={5} className='animate__animated animate__fadeInUpSmall '>

                  <Card className='overflow-visible h-100 d-flex'>
                    <CardContent className='d-flex flex-column flex-grow-1'>
                      <ModalTarifa2 handleShowModal_Registro={handleShowModal_Registro} />
                    </CardContent>
                  </Card>

                </Grid>
              </>
            }

            {
              verMas &&
              <Grid item md={12} className='text-center'>

                <Button variant='contained' className={`mx-auto ${verMas ? '' : 'mb-5'}`}
                  // onClick={() => setverMas(!verMas)}
                  onClick={e => {
                    setverMas(!verMas)
                    let hero = document.getElementById("before-productos");
                    e.preventDefault();

                    hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
                  }}
                >
                  {Lang('OCULTAR')}
                </Button>

              </Grid>
            }

            <Grid item xs={12} className='text-center mb-4'></Grid>


          </Grid>

        </Container>

        {/* <ModalTarifa1
          data={dataModal_Tarifa1}
          handleClose={handleCloseModal_Tarifa1}
        />

        <ModalTarifa2
          data={dataModal_Tarifa2}
          handleClose={handleCloseModal_Tarifa2}
        /> */}

      </section>

      <section className='PageIndex__Colegios' id='seccion-convenios'>

        <div className="border-top py-5">

          <ListConvenios />

        </div>

        <div className="text-center pb-5">

          <div className='mb-4'>{Lang('COLABORACION_DE')}:</div>

          <a href="http://www.ecatecnia.com" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/logos/ecatecnia.png`} alt="ecatecnia" style={{ maxWidth: '250px' }} />
          </a>

        </div>

      </section>

      <ModalInfo
        data={dataModal_InfoError}
        handleClose={handleCloseModal_InfoError}
        message={messageModal}
      />

      <ModalInfo
        data={dataModal_InfoTipoEdif}
        handleClose={handleCloseModal_InfoTipoEdif}
        message={dataModal_InfoTipoEdif.message}
        size='lg'
        disableIconTitle={true}
      />

      <ModalRegistroUsuario
        data={dataModal_Registro}
        handleClose={handleCloseModal_Registro}
      />

      <Footer />

      <Faqs
        data={dataModal_Faqs}
        handleClose={handleCloseModal_Faqs}
      />

      {/* <AlertasGenerales show={isBetweenDates('2024-07-29', '2024-08-23')} /> */}

    </section>
  );
};

export default Index;
