import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useForm } from "../../../../../../../../hooks/useForm"
import { useValidator } from "../../../../../../../../hooks/useValidator"
import { isStatusSuccess } from "../../../../../../../../helpers/helpers"
import { Box, FormHelperText, Grid, Typography } from "@mui/material"
import { InputFile } from "../../../../../../../../components/Inputs/InputFile"
import ModalAdvanced from "../../../../../../../../components/ModalAdvanced/ModalAdvanced"
import { ButtonAdvanced } from "../../../../../../../../components/ButtonAdvanced/ButtonAdvanced"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { ModalStatusServer } from "../../../../../../../../components/Modals/ModalStatusServer"
import { useLang } from "../../../../../../../../language"
import { FicheroITEServices } from "../controllers/ficheroite.services"

export const ModalCargarITE = ({ data, handleClose, fetchParamsAgentesGETData, idedificio, fetchSuccess,
    formValuesSearch, fetchParamsDocumentosGETData, id }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()


    const [fetchMantPrevPOST, fetchMantPrevPOSTData, resetfetchMantPrevPOSTData] = FicheroITEServices.POST()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        file: ''
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        // nom: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // tipus_document: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchMantPrevPOST({
                body: {
                    ...formDocumento,
                    edifici_id: idedificio
                }
            })
        }
    }

    useEffect(() => {

        if (data.value && isStatusSuccess(fetchMantPrevPOSTData.status)) {

            fetchSuccess()
            handleClose()

            if (!data.avoidRedirect) {
                
                navigate('./importar-ite')
            }

            resetfetchMantPrevPOSTData()
        }

    }, [fetchMantPrevPOSTData, data.value]);

    useEffect(() => {

        if (data.value) {


        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='md'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('IMPORTAR_ITE')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={3} rowSpacing={3}>

                            <Grid item xs={12}>
                                <div dangerouslySetInnerHTML={{ __html: Lang('P_INFO_IMPORTAR_ITE') }} />
                            </Grid>

                            <Grid item xs={12}>

                                <InputFile
                                    label={Lang('DOCUMENTO') + ': *'}
                                    path={formDocumento.ruta}
                                    name='file'
                                    onChange={handleInputDocumento}
                                    readOnly={data?.action === 'EDIT'}
                                />

                                {
                                    fetchMantPrevPOSTData?.error?.errors?.['file'] &&
                                    <FormHelperText error>
                                        {
                                            fetchMantPrevPOSTData?.error?.errors?.['file']
                                        }
                                    </FormHelperText>
                                }

                            </Grid>

                        </Grid>
                    </Box>
                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="contained" type="button" className="mt-3 ms-auto"
                        handleSubmit={handleSubmitDocumentoPOST}
                        loadings={[fetchMantPrevPOSTData.loading]}
                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                    />
                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchMantPrevPOSTData} />
        </>
    )
}